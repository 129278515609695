import './index.scss';

import React from 'react';

import Cta from '../../components/Cta';
import browntree from '../../components/browntree-green-circle.png';
import headshot from './cpow-headshot.png';
import Layout from "../../components/Layout";

const AboutPage = () => (
    <Layout>
      <div className="aboutPage">
        <div className="jumbotron bg-white text-center">
          <h1 className="display-4">Ahem, is this thing on?</h1>
          <div className="container">
            <p className="h4 font-italic text-secondary">BrownTree Labs is an independent software consultancy. Hoping to work on interesting projects and leave the world a better place than how we found it.</p>
          </div>
        </div>
        <div className="aboutText container mb-5" style={{minHeight: 900}}>
          <div className="row mb-5">
            <div className="col-sm-4">
              <img src={headshot} className="rounded" />
            </div>
            <div className="col-sm-8">
              <div className="font-italic text-secondary h4">
                about
              </div>
              <h2>
                Chris Power
              </h2>
              <hr />
              <p className="h5 mb-4">
                I am the founder and lead developer at BrownTree Labs. I also happen to be a software developer who is passionate about code quality, beautiful design, and creating amazing products.
              </p>
              <p className="h5">
                I'm also a <strong className="font-italic">human being</strong> with a beautiful wife and two babies on the way! I currently live in Black Rock, CT and mostly work out of NYC. When I'm not working, I enjoy reading, snowboarding, riding my <a href="https://boostedboards.com">ridiculous electric longboard</a>, and golfing!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div className="font-italic text-secondary h4">
                about
              </div>
              <h2>
                Browntree
              </h2>
              <hr />
              <p className="h5 mb-4">
                Browntree Labs is the way in which I work. I love creating products, and working across a broad spectrum of industries, and technologies.
              </p>
              <p className="h5 mb-4">
                I started Browntree Labs in February 2013 because I wanted to be more independent. I wanted to have a business of my own, and to grow that business. As of this moment, we've worked with companies ranging from publicly traded health care companies, to small sports startups; and we're only growing!
              </p>
            </div>
            <div className="col-sm-4">
              <img src={browntree} />
            </div>
          </div>
          <div className="mt-5">
            <Cta />
          </div>
        </div>
      </div>
    </Layout>
);

export default AboutPage;
