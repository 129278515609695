import React from 'react';

const Cta = () => (
  <div className="card bg-light-orange">
    <div className="card-body">
      <div className="h2 text-center mb-2 font-weight-bold">
        Lets <span className="highlight">Work</span> Together
      </div>
      <p className='text-center mt-2 font-italic'>We're trusted by large, medium, and small companies <span className="font-italic highlight"> all over the world</span></p>
      <p className='text-center mt-2 font-italic'>Have something you're working on?</p>
      <a className="btn btn-block btn-orange text-light" href="/contact">Tell Us About It</a>
    </div>
  </div>
);

export default Cta;
